<template>
  <div class="analyse">
    <div v-for="(item, i) in subjectCombine" :key="i" :class="{
      act: nav_id == item.id ? true : false,
      subjectCard: true
    }" @click="chooseCombine(item)">
      {{ item.name }}
      <p>匹配率：院校 {{ Math.trunc(item.college_percent) }}% 专业 {{ Math.trunc(item.specialty_percent) }}%</p>
    </div>

    <div v-for="(item, i) in otherCombine" :key="i + 12" :class="{
      act: nav_id == item.id ? true : false,
      subjectCard: true,
      hidden_bg: true
    }">
      {{ item.name }}
      <p>匹配率：院校 {{ Math.trunc(item.college_percent) }}% 专业 {{ Math.trunc(item.specialty_percent) }}%</p>
    </div>
    <div class="remarks-a">
      注：根据河南省各个高中的实际情况，各个高中开通的选科组合可能不同，你可通过设置限定选科组合的功能设定你学校开通的选科组合，从中间确定自己最终要选择的组合
    </div>

    <div class="title">
      <el-row>
        <el-col :span="4">已选组合:{{ combineData.name }}</el-col>
        <el-col :span="6">匹配率:院校 {{ Math.trunc(combineData.college_percent) }}% 专业 {{
          Math.trunc(combineData.specialty_percent)
        }}%</el-col>
        <el-col :span="4">意向院校:
          <el-dropdown>
            <span v-if="choosCollegeList" style="font-size: 16px;margin-left: 10px;">
              {{ choosCollegeList.length }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span v-else style="font-size: 16px;margin-left: 10px;">0</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, i) in choosCollegeList" :key="i">{{ item }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="4">意向专业(大类):
          <el-dropdown>
            <span v-if="categroyCode" style="font-size: 16px;margin-left: 10px;">
              {{ categroyCode.length }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <span v-else style="font-size: 16px;margin-left: 10px;">0</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, i) in category_list" :key="i">{{ item.specialty_name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
        <el-col :span="3" :offset="3"> <span style="color: #e45a01;" @click="saveChoose()">确定选科组合</span>
        </el-col>
      </el-row>
    </div>

    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="院校优先" name="first">
        <college-first :choosCollegeList="choosCollegeList" @chooseCollege="chooseCollege"></college-first>
      </el-tab-pane>

      <el-tab-pane label="专业优先" name="second">
        <specialty-first :categroyCode='categroyCode'
          @chooseSpecialtyCategory="chooseSpecialtyCategory"></specialty-first>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import CollegeFirst from "./college-first"
import SpecialtyFirst from "./specialty-first"
export default {
  name: '',
  data() {
    return {
      subjectCombine: [],
      otherCombine: [],
      nav_id: 1,
      combineData: [],
      activeName: "first",
      choosCollegeList: [],
      category_list: [],
      categroyCode: []
    };
  },
  components: {
    CollegeFirst,
    SpecialtyFirst
  },
  created() {

    this.getSubectList()
  },

  methods: {
    getSubectList() {
      this.$fecth.post("xuanke/x_specialty/getNavList").then((res) => {
        this.subjectCombine = res.subjectCombine
        this.otherCombine = res.otherCombine
        this.nav_id = this.subjectCombine[0].id
        this.getUserCombineList(this.nav_id)
        this.combineData = this.subjectCombine[0]
      });
    },
    chooseCombine(item) {
      this.nav_id = item.id
      this.getUserCombineList(this.nav_id)
      this.combineData = item
    },


    getUserCombineList(combine_id) {

      let data = {
        combine_id
      }
      this.$fecth.post("xuanke/user_combine_list/getList", data).then((res) => {
        this.choosCollegeList = res.college_list
        this.choosCollegeList = this.choosCollegeList == null ? [] : this.choosCollegeList
        this.category_list = res.category_list
        if (this.category_list) {
          this.categroyCode = []
          this.category_list.forEach((item) => {
            this.categroyCode.push(item.code_id)
          })
        } else {
          this.category_list = []
          this.categroyCode = []
        }
      });
    },

    chooseCollege(item) {
      let i = this.choosCollegeList.indexOf(item)
      if (i > -1) {
        this.choosCollegeList.splice(i, 1)
      } else {
        this.choosCollegeList.push(item)
      }
    },

    chooseSpecialtyCategory(item) {
      let i = this.categroyCode.indexOf(item.code_id)
      if (i > -1) {
        this.categroyCode.splice(i, 1)
        this.category_list.splice(i, 1)
      } else {
        this.categroyCode.push(item.code_id)
        this.category_list.push(item)
      }
    },

    saveChoose() {
      let data = {
        combine_id: this.nav_id,
        college_list: this.choosCollegeList,
        category_list: this.category_list,
        code_list: this.categroyCode
      }
      this.$fecth.post("xuanke/user_combine_list/saveChoose", data).then((res) => {
        if (res) {
          this.$notify({
            offset: 100,
            showClose: true,
            message: "保存成功",
            type: "success",
          });
        }

      });
    },

    handleClick() {

    }
  },
};
</script>

<style scoped lang='less'>
.subjectCard {
  display: inline-block;
  color: #702b1b;
  background: #fdf5ea;
  text-align: center;
  width: 193px;
  padding: 14px 0px;
  margin-top: 10px;
  margin-right: 8px;
  border-radius: 5px;
  font-size: 20px;
  letter-spacing: 2px;

  p {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: 0px;
  }
}

.subjectCard:nth-child(6) {
  margin-right: 0;
}

.subjectCard:nth-child(12) {
  margin-right: 0;
}

.act {
  // padding: 16px 56px;
  border: #edb290 1px solid;
  background: #f8d0b6;
}

.analyse {
  .remarks-a {
    margin-top: 20px;
    padding: 10px 20px;
    line-height: 160%;
    color: #838381;
    background: #fdf5ea;
  }
}

.hidden_bg {
  background: #e7e7e7 !important;
  color: #6e6e6e !important;
}


.title {
  color: #702b1b;
  padding: 0 40px;
  background: #fdf5ea;
  border-radius: 5px;
  line-height: 70px;
  margin: 20px 0;
  font-size: 18px;
  border: #edb290 1px solid;
  font-weight: 500;
}
</style>
